/* You can add global styles to this file, and also import other style files */
//@import "../node_modules/bootstrap/dist/css/bootstrap.min.css"; 
@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import "bootstrap/dist/css/bootstrap.min.css";

.main-container {
  position: relative;
  margin: 5px;
}

html,body {
  height: 100%;
  background-color: white;
}

:root {
  --mk3red: #e1182d; 
}

.centered-div{
    display:flex;
    //flex-direction: column;
    height: 100%;
    //position:absolute;
    //left:50%;
    //top:50%;
    //transform: translate(-50%, -50%);
    // -webkit-transform: translateY(-50%);
    // -ms-transform: translateY(-50%);
    // transform: translateY(-50%);
    //text-align:center;
    margin: auto;
    text-align: center;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
  }
  
  
.ltable {
  width: 100%;
  vertical-align: middle;
  margin-bottom: 1rem;
  color: #212529;
}

.login-form-container {
  position: fixed;
  top: 25%;
  left: 35%;
}

/*Portal Header */
.portal-header {
  background-color: var(--mk3red);
  color: white;
  width: 100%;
  height: 45px;
  align-items: center;
  //text-align: center;
  margin-top: 55px;
  padding-top: 10px;
  margin-left: 155px;
}

.header-text {
  text-align: center;
  padding-right:155px;
}

.btn {
  background-color: var(--mk3red);
  color: white;
}

/*CAT Filter */
.vertical-form-field {
  position: relative;
  margin: 0px;
  align-self: center;
  display: flex;
  justify-content: center;
  padding: 0px;
  height: 65px;
}

.main-table-container {
  position: relative;
  margin-top: 0px;
  margin-left: 155px;
  //changed main table width
  width: 90%;
}

button:hover {
  background-color: #cfd8dc;
}

.mat-typography h1 {
  text-align: center;
  color: var(--mk3red);
  margin: 0 0 0;
}

h2 {
  text-align: center;
  margin-bottom: 0px;
}

.home-container {
  margin-top: 50px;
  margin-left: 190px;
  text-align: center;
}

h3 {
  text-align: center;
  font-weight: 500;
  margin-bottom: 0px;
}

h4 {
  text-align: center;
  margin-bottom: 0px;
}

.green-icon {
  color: green;
}

.red-icon {
  color: var(--mk3red);
}

.center-button-container {
  position: relative;
  margin: 0px;
  align-self: center;
  display: flex;
  justify-content: center;
  padding: 20px;
}

button {
  background-color: #eee;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  cursor: hand;
}

.red-button{
  background-color: var(--mk3red); 
  color:white ;
}

.red-button:hover {
  color: white;
  background-color: #481d97;
  border-color: #bd2130;
}
.red-button:focus, .btn-danger.focus {
  color: white;
  background-color: var(--mk3red);
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}


.btn-danger {
  background-color: var(--mk3red) !important;
  color: white;
}

.navbar.bg-dark {
  //background-color: #06223d !important;//#343a40 !important
  background-color: var(--mk3red) !important;
  padding: 0 0 0;
}

//Paginator
.mat-paginator {
  padding: 0;
  margin: 0px;
  text-align: center;
  align-self: center;
  width: 100%;
  color: black;
  background-color: #f8f9fa;
}

//Row Hover
.row-hover {
  //color: blue;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
  padding-left: 0.5em;
  padding-right: 1em;
  padding-bottom: 0.1em;
}

.row-hover:hover {
  //background-color: rgba(52, 100, 202, 0.356);
  color: slategrey;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: inset 0px -7px 15px 5px #0a2f5555;
}

/* Mat Table Common Styles */
table {
  margin: 0px auto;
  //width: 90%;
}

tr.mat-header-row {
  height: 40px;
}

th.mat-sort-header-sorted {
  color: rgb(162, 87, 233);
}

th.mat-header-cell .mat-sort-header-container.mat-sort-header-sorted .mat-sort-header-arrow {
  color: blueviolet;
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.mat-row {
  height: 30px;
  color: #1a084c;
}

::ng-deep th.mat-header-cell {
  text-align: center;
  color: rgb(241, 243, 230);
  background-color: var(--mk3red) !important;
  color: white !important;
  font-size: 14px;
  height: 30px;
  border-bottom-width: 0px;
}

th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  padding-left: 5px;
  //border-bottom-width: 0px;
}

th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type {
  padding-right: 5px;
  //border-bottom-width: 0px;
}

/*get rid of the lines from the header and footer of the table*/
th.mat-header-cell,
td.mat-footer-cell {
  border-bottom-width: 0px;
  border-bottom-style: none;
}

.mat-cell {
  border: 1px solid lightgray;
  padding: 0 5px 0 5px;
  text-align: center;
  //border-bottom-width: 0px;
}

// .mat-cell {
//   border-bottom-width: 0px;
// }


/*column widths*/
.w-2 {
  width: 2%;
}

.w-3 {
  width: 3%;
}

.w-5 {
  width: 5%;
}

.w-8 {
  width: 8%;
}

.w-10 {
  width: 10%;
}

.w-12 {
  width: 12%;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 20%;
}

.w-50 {
  width: 20%;
}


/* Search Box Container */
.search-box-container {
  position: relative;
  margin: 0px;
  align-self: center;
  display: flex;
  justify-content: center;
  padding: 0px;
  padding-left:165px;
}

/* Search Box Container */
.drop-down-list-container {
  position: relative;
  margin: 0px;
  align-self: center;
  display: flex;
  justify-content: center;
  padding: 0px;
}

.search-box-form-field {
  width: 300px;
}

.mat-table caption {
  padding-top: 0.5rem;
  padding-bottom: 0;
  text-align: center;
}

.loading-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
  //background-color: #fff;
  z-index: 99;
}

.lds-grid {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  margin: 20px;
}

.lds-grid div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--mk3red);
  animation: lds-grid 1.2s linear infinite;
}

.lds-grid div:nth-child(1) {
  top: 6px;
  left: 6px;
  animation-delay: 0s;
}

.lds-grid div:nth-child(2) {
  top: 6px;
  left: 26px;
  animation-delay: -0.4s;
}

.lds-grid div:nth-child(3) {
  top: 6px;
  left: 45px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(4) {
  top: 26px;
  left: 6px;
  animation-delay: -0.4s;
}

.lds-grid div:nth-child(5) {
  top: 26px;
  left: 26px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(6) {
  top: 26px;
  left: 45px;
  animation-delay: -1.2s;
}

.lds-grid div:nth-child(7) {
  top: 45px;
  left: 6px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(8) {
  top: 45px;
  left: 26px;
  animation-delay: -1.2s;
}

.lds-grid div:nth-child(9) {
  top: 45px;
  left: 45px;
  animation-delay: -1.6s;
}

@keyframes lds-grid {


  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

a {
  cursor: pointer;
}

.app-container {
  min-height: 155px;
  overflow: hidden;
  width: 98%;
    //display: flex;
  align-content: center;
  //vertical-align: middle;
  justify-content: center;
  //text-align: center;
  //align-items: center;
  align-self: center;
  margin-left: 1%;
}

.btn-delete-user {
  width: 40px;
  text-align: center;
  box-sizing: content-box;
}

// Sweet Alerts Customization
.swal2-title {
  margin: 0px;
  font-size: 46px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.21);
  margin-bottom: 28px;
  font-family: Courier New, monospace;
  font-style: italic;
}

.swal2-modal {
  //background-color: rgba(63, 255, 106, 0.69);
  background-color: white;
  //border: 3px solid white;
  border: 3px solid red;
}

.swal2-container {
  zoom: 0.75;
}

.swal2-icon {
  width: 5em !important;
  height: 5em !important;
  border-width: .25em !important;
}

.swal-text {
  background-color: #FEFAE3;
  padding: 17px;
  border: 1px solid #F0E1A1;
  display: block;
  margin: 22px;
  text-align: center;
  color: #61534e;
}

.swal-button {
  padding: 7px 19px;
  border-radius: 2px;
  background-color: #4962B3;
  font-size: 12px;
  border: 1px solid #3e549a;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}

.swal-footer {
  background-color: rgb(245, 248, 250);
  margin-top: 32px;
  border-top: 1px solid #E9EEF1;
  overflow: hidden;
}

.mat-grid-tile .mat-figure {
  align-items: flex-start;
}

.highlight-table-row {
  background-color: rgb(252, 225, 230);
  font-weight: 500;
}

.fixed-open {
  display: block;
}
th.mat-mdc-header-cell, td.mat-mdc-footer-cell {
  font-size: 14px;
  background-color: var(--mk3red) !important;
  color: white !important;
  text-align: center;
}
.mat-mdc-cell {
  border: 1px solid lightgray;
  padding: 0 5px 0 5px;
  text-align: center;
}
app-shared-files{
  display: flex;justify-content: center;
}
.mat-card, .mat-mdc-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-mdc-card{
  box-shadow: none!important;
}
.mat-mdc-dialog-surface {
  display: block;
  padding: 24px;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
}
.mat-mdc-dialog-surface {
  background: #fff;
  color: rgba(0, 0, 0, .87);
}
.mat-mdc-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif!important;
  letter-spacing: normal!important;
  padding: 0px 30px !important;
  display: flex!important;
  height: 32px!important;
}

button.btn,a,h1,h2,h3,h4,h5 {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}